import { boolean, coerce, enums, optional, refine, string, type } from "superstruct";

const Str = string();
const Bool = coerce(boolean(), enums(["true", "false"]), x => x === "true");
const Url = refine(string(), "Url", url => {
    if (url.startsWith("https://") && !url.endsWith("/")) return true;
    return `Expected URL to start with https:// and NOT end with /`;
});

export const EnvSchema = type({
    /** @deprecated - Use `parsedEnv.MODE === "development"` instead.
     * `parsedEnv.DEV` is only true on vite dev server. */
    DEV: boolean(),
    // This corresponds to the modes at scripts/environments.js
    MODE: enums(["development", "staging", "uat", "release", "production"]),

    VITE_API_GATEWAY: optional(Str),
    VITE_EASTER_EGG_TUPNI_ESREVER: Bool,
    VITE_FEATURE_CREATE_CATEGORY_AT_ROOT_BUTTON: Bool,
    VITE_FEATURE_EDIT_PROFILE: Bool,
    VITE_FEATURE_FILE_EXPLORER_SEARCHBAR: Bool,
    VITE_FEATURE_FIREBASE_ACCOUNT_LINKING: Bool,
    VITE_FEATURE_FIREBASE_INVITE_FROM_FRONTEND: Bool,
    VITE_FEATURE_ONBOARDING_PERSONAL_DATA_FORM: Bool,
    VITE_FEATURE_ORGANIZATION_CHART: Bool,
    VITE_FEATURE_SIGN_IN_WITH_GOOGLE: Bool,
    VITE_FEATURE_SIGN_IN_WITH_MAGIC_CODE: Bool,
    VITE_FEATURE_SIGN_IN_WITH_MAGIC_LINK: Bool,
    VITE_FEATURE_SIGN_IN_WITH_OIDC: Bool,
    VITE_FEATURE_SIGN_IN_WITH_PASSWORD: Bool,
    VITE_FEATURE_SIGN_IN_WITH_SAML: Bool,
    VITE_FEATURE_SIMULATE_ERROR_BUTTON: Bool,
    VITE_FEATURE_WORKFLOWS_EXECUTE_FROM_MENU: Bool,
    VITE_MOCK_ATLAS: Bool,
    VITE_PACKAGE_JSON_VERSION: Str,

    // Servers can be either the URL of the server or the service name (for API Gateway)
    VITE_SERVER_ASSET: Str,
    VITE_SERVER_DOCUMENT_MANAGER: Str,
    VITE_SERVER_NOTIFICATIONS: Str,
    VITE_SERVER_PEOPLE: Str,
    VITE_SERVER_NEW_PEOPLE: Str,
    VITE_SERVER_WORKFLOW: Str,

    // VITE_SERVICE_* variables are read and validated at getApiInstance instead of here.

    VITE_TASK_MANAGER_HOME_ENABLED: Bool,
    /** Used for web push notifications. */
    VITE_VAPID_KEY: Str,
    /** This always points to the deployed web app (not localhost), even on mobile. */
    VITE_WEB_URL: Url,
    /** Used for differentiate environments */
    VITE_COLOR: optional(Str),
    VITE_BADGE: optional(Str),
});
