import "solid-devtools";
import "temporal-polyfill/global";
import { render } from "solid-js/web";
import { RouteDefinition, Router, useNavigate } from "@solidjs/router";
import { I18nProvider, useLocale } from "./modules/i18n/context";
import SafeAreaWrapper from "./utils/SafeAreaWrapper";
import ProtectedRouteWrapper from "./modules/menu/ProtectedRouteWrapper";
import {
    LoremAdvancedPage,
    LoremAssetsPage,
    LoremLinksPage,
    LoremMyAccountPage,
    LoremOrganizationPage,
    LoremPage,
    LoremReportsPage,
    LoremToolsPage,
} from "./utils/loremPages";
import GenericErrorBoundary from "./utils/GenericErrorBoundary";
import { AuthProvider } from "./modules/auth/authContext";
import { lazy, onMount, ParentProps, Suspense } from "solid-js";
import { QueryClientProvider } from "@tanstack/solid-query";
import { SolidQueryDevtools } from "@tanstack/solid-query-devtools";
import { queryClient } from "./api/utils";
import "toastify-js/src/toastify.css";
import { attachDevtoolsOverlay } from "@solid-devtools/overlay";
import { App } from "@capacitor/app";
import { fireAndForget } from "./utils/async";
import { GenericSuspenseFallback } from "./modules/ui/skeletons";
import ChunkLoadErrorBoundary from "./utils/ChunkLoadErrorBoundary";
import { ClockProvider } from "./utils/clock";
import { SubdomainProvider } from "./api/SubdomainProvider";

attachDevtoolsOverlay();

const ActivitiesDetailPage = lazy(() => import("./modules/Activities/ActivitiesDetailPage"));
const ActivitiesPage = lazy(() => import("./modules/Activities/ActivitiesPage"));
const ActivitiesPlaygroundPage = lazy(
    () => import("./modules/Activities/ActivitiesPlaygroundPage"),
);
const AgendaPage = lazy(() => import("./modules/Activities/AgendaPage"));
const AudienceCreatePage = lazy(() => import("./modules/audiences/AudienceCreatePage"));
const AudiencesDetailPage = lazy(() => import("./modules/audiences/AudiencesDetailPage"));
const AudiencesPage = lazy(() => import("./modules/audiences/AudiencesPage"));
const ChecklistExecutionDetailPage = lazy(
    () => import("./modules/checklists/ChecklistExecutionDetailPage"),
);
const ChecklistExecutionPage = lazy(() =>
    import("./modules/checklists/ChecklistPage").then(module => ({
        default: module.ChecklistExecutionPage,
    })),
);
const ChecklistExecutionsExecutePage = lazy(
    () => import("./modules/checklists/ChecklistExecutionsExecutePage"),
);
const ChecklistExecutionsListPage = lazy(
    () => import("./modules/checklists/ChecklistExecutionsListPage"),
);
const ChecklistsAdministrationPage = lazy(() =>
    import("./modules/checklists/ChecklistPage").then(module => ({
        default: module.ChecklistsAdministrationPage,
    })),
);
const ChecklistsExecutePage = lazy(() => import("./modules/checklists/ChecklistsExecutePage"));
const CreateAccountFromInvite = lazy(() => import("./modules/onboarding/CreateAccountFromInvite"));
const CreateActivityPage = lazy(() => import("./modules/Activities/CreateActivityPage"));
const EditProfilePage = lazy(() => import("./modules/personal-data/EditProfilePage"));
const HomePage = lazy(() => import("./modules/home/HomePage"));
const IndexPage = lazy(() => import("./modules/auth/IndexPage"));
const InvitePage = lazy(() => import("./modules/onboarding/InvitePage"));
const LoginDemoPage = lazy(() => import("./modules/auth/LoginDemoPage"));
const NewChecklistPage = lazy(() => import("./modules/checklists/NewChecklistPage"));
const NewExecutionPage = lazy(() => import("./modules/workflows/NewExecutionPage"));
const NewPostPage = lazy(() => import("./modules/posts/NewPostPage"));
const NotificationsPage = lazy(() => import("./modules/notifications/NotificationsPage"));
const OnboardingPage = lazy(() => import("./modules/personal-data/OnboardingPage"));
const OrganizationChartPage = lazy(
    () => import("./modules/organization-chart/OrganizationChartPage"),
);
const PeopleBulkLoadPage = lazy(() => import("./modules/personal-data/PeopleBulkLoadPage"));
const PeopleDetailPage = lazy(() => import("./modules/personal-data/PeopleDetailPage"));
const PeoplePage = lazy(() => import("./modules/personal-data/PeoplePage"));
const PostDetailPage = lazy(() => import("./modules/posts/PostDetailPage"));
const PostsPage = lazy(() => import("./modules/home/PostsPage"));
const ProfilePage = lazy(() => import("./modules/personal-data/ProfilePage"));
const RouteNotFoundPage = lazy(() => import("./utils/RouteNotFoundPage"));
const SettingsPage = lazy(() => import("./modules/settings/SettingsPage"));
const SignInFromMagicLink = lazy(() => import("./modules/auth/SignInFromMagicLink"));
const SignInPage = lazy(() => import("./modules/auth/SignInPage"));
const StartWorkflowExecutionPage = lazy(
    () => import("./modules/workflows/StartWorkflowExecutionPage"),
);
const SyncPage = lazy(() => import("./modules/sync/SyncPage"));
const WorkflowDetailPage = lazy(() => import("./modules/workflows/WorkflowDetailPage"));
const WorkflowExecutionAdministrationDetailPage = lazy(
    () => import("./modules/workflows/WorkflowExecutionAdministrationDetailPage"),
);
const WorkflowExecutionDetailPage = lazy(
    () => import("./modules/workflows/WorkflowExecutionDetailPage"),
);
const WorkflowFormBuilderPage = lazy(() => import("./modules/workflows/WorkflowFormBuilderPage"));
const WorkflowsAdministrationPage = lazy(
    () => import("./modules/workflows/WorkflowsAdministrationPage"),
);
const WorkflowsExecutionsPage = lazy(() => import("./modules/workflows/WorkflowsExecutionsPage"));
const WorkflowTypeAdministrationPage = lazy(
    () => import("./modules/workflows/WorkflowTypeAdministrationPage"),
);

const protectedRoutes: RouteDefinition = {
    path: "/",
    component: ProtectedRouteWrapper,
    children: [
        { path: "/activities", component: ActivitiesPage },
        { path: "/activities/:id/:recurrenceId", component: ActivitiesDetailPage },
        { path: "/activities/create", component: CreateActivityPage },
        { path: "/activities/create/:id", component: CreateActivityPage },
        { path: "/activities/agenda", component: AgendaPage },
        { path: "/activities/playground", component: ActivitiesPlaygroundPage },
        { path: "/advanced", component: LoremAdvancedPage },
        { path: "/assets", component: LoremAssetsPage },
        { path: "/audiences", component: AudiencesPage },

        { path: "/audiences/:id", component: AudiencesDetailPage },
        { path: "/audiences/create", component: AudienceCreatePage },
        { path: "/checklist-administration", component: ChecklistsAdministrationPage },
        {
            path: "/checklist-administration/:category_id",
            component: ChecklistsAdministrationPage,
        },
        { path: "/checklists", component: ChecklistExecutionPage },
        { path: "/checklists/:category_id", component: ChecklistExecutionPage },
        { path: "/checklists/:id/execute", component: ChecklistsExecutePage },
        // Refactor needed: "/checklists/:id/execute/:id" should be ChecklistExecutionsExecutePage
        { path: "/checklists/executions", component: ChecklistExecutionsListPage },
        { path: "/checklists/executions/:execution_id", component: ChecklistExecutionsExecutePage },
        {
            path: "/checklists/executions_detail/:execution_id",
            component: ChecklistExecutionDetailPage,
        },
        { path: "/checklists/update/:id?", component: NewChecklistPage },
        { path: "/checklists/create/:categoryId", component: NewChecklistPage },
        { path: "/home", component: HomePage },
        { path: "/invite", component: InvitePage },
        { path: "/links", component: LoremLinksPage },
        { path: "/lorem-ipsum/:title/:id", component: LoremPage },
        { path: "/my-account", component: LoremMyAccountPage },
        { path: "/notifications", component: NotificationsPage },
        { path: "/organization", component: LoremOrganizationPage },
        { path: "/org-chart", component: OrganizationChartPage },
        { path: "/people", component: PeoplePage },
        { path: "/people/:id", component: PeopleDetailPage },
        { path: "/people-bulk-load/:id", component: PeopleBulkLoadPage },
        { path: "/posts", component: PostsPage },
        { path: "/posts/:id", component: PostDetailPage },
        { path: "/posts/new", component: NewPostPage },
        { path: "/profile", component: ProfilePage },
        { path: "/profile/edit", component: EditProfilePage },
        { path: "/reports", component: LoremReportsPage },
        { path: "/settings", component: SettingsPage },
        { path: "/sync", component: SyncPage },
        { path: "/tools", component: LoremToolsPage },
        { path: "/workflows", component: WorkflowsAdministrationPage },
        { path: "/workflows/:id", component: WorkflowDetailPage },
        { path: "/workflows/:workflowType/execute", component: NewExecutionPage },
        { path: "/workflows/:workflowType/executions", component: WorkflowsExecutionsPage },
        {
            path: "/workflows/:workflowType/administration",
            component: WorkflowTypeAdministrationPage,
        },
        {
            path: "/workflows/:workflowType/administration/:category_id",
            component: WorkflowTypeAdministrationPage,
        },
        {
            path: "/workflows/:workflowType/executions/:workflowId/:executionId",
            component: WorkflowExecutionDetailPage,
        },
        {
            path: "/workflows/:workflowType/executions-administration/:workflowId/:executionId",
            component: WorkflowExecutionAdministrationDetailPage,
        },
        {
            path: "/workflows/:workflowType/:workflowId/start-execution/:bpmnId",
            component: StartWorkflowExecutionPage,
        },
        {
            path: "/workflows/:workflowId/form-builder/:formId?",
            component: WorkflowFormBuilderPage,
        },
    ],
};

const publicRoutes: RouteDefinition[] = [
    { path: "/", component: IndexPage },
    { path: "/from-invite", component: CreateAccountFromInvite },
    { path: "/from-magic-link", component: SignInFromMagicLink },
    { path: "/login", component: LoginDemoPage },
    { path: "/onboarding", component: OnboardingPage },
    { path: "/sign-in", component: SignInPage },
    {
        path: "/public/workflows/:workflowType/:workflowId/start-execution/:bpmnId",
        component: StartWorkflowExecutionPage,
    },
    protectedRoutes,
    { path: "*", component: RouteNotFoundPage },
];

function RootLayout(props: ParentProps) {
    useAndroidBackButton();

    return (
        <GenericErrorBoundary>
            <ChunkLoadErrorBoundary>
                <QueryClientProvider client={queryClient}>
                    <AuthProvider>
                        <ClockProvider>
                            <Suspense fallback={<GenericSuspenseFallback />}>
                                <SubdomainProvider>{props.children}</SubdomainProvider>
                            </Suspense>
                        </ClockProvider>
                    </AuthProvider>
                    <SolidQueryDevtools />
                </QueryClientProvider>
            </ChunkLoadErrorBoundary>
        </GenericErrorBoundary>
    );
}

function useAndroidBackButton() {
    const navigate = useNavigate();
    const [locale] = useLocale();

    onMount(() => {
        fireAndForget(
            App.addListener("backButton", ({ canGoBack }) => {
                const pathname = window.location.pathname;
                if (canGoBack) {
                    navigate(-1);
                } else if (!["/", "/login", "/home"].includes(pathname)) {
                    navigate("/", { replace: true });
                } else if (confirm(locale().utils.confirmExitApp)) {
                    fireAndForget(App.exitApp());
                }
            }),
        );
    });
    // no onCleanup as this never unmounts in practice
}

// Don't inline this variable, or it will mess up the hot reload
const root = document.getElementById("root")!;

// This must be done after everything else, or the code generated by Solid will crash
render(
    () => (
        <SafeAreaWrapper>
            <I18nProvider>
                <Router root={RootLayout}>{publicRoutes}</Router>
            </I18nProvider>
        </SafeAreaWrapper>
    ),
    root,
);
